import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)
export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg' // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#0072CE',
        secondary: '#52657D',
        success: '#66BB6A',
        error: '#E60909',
        lightBlue: '#D7E0FC', // '#E6F0FF',
        lightRed: '#FACECE',
        defaultGrey: '#525F7F',
        background: '#F4F6FA',
        // new definition
        lightGrey: '#F4F6FA',
        fontDraw: '#607D8B',
        fontBody: '#52657D',
        bgSearch: '#FFFFFF',
        bgPanel: '#FFFFFF',
        greyChip: '#63627C',
        placeholder: '#CFD8DC',
        off: '#EDEEF6',
        supportAlert: '#151E34',
        barCompliance: '#FFFFFF',
        blueDarken: '#080C24',
        skeleton: '#CFD8DC',
        yellow: '#E8A803',
        yellowLight: '#FEF2CC',
        redLight: '#F9DAD9',
        greyMedium: '#C3C7D2',
        overlay: '#525F7F',
        secondaryIconDrawer: '#52657D',
        fontLinkTable: '#52657D',
        blueGreyLighten: '#C8D2D7',
        fontChip: '#52657D'
      },
      dark: {
        primary: '#0072CE',
        secondary: '#E6E8FC',
        error: '#BE2D2F',
        background: '#080C24',
        lightGrey: '#080C24',
        bgSearch: '#151E34',
        bgPanel: '#151E34',
        defaultGrey: '#9FA8DA',
        greyChip: '#FFFFFF',
        off: '#9FA8DA',
        barCompliance: '#151E34',
        yellowLight: '#FFF4DB',
        redLight: '#ECC0C1',
        lightBlue: '#D0DCF3',
        greyMedium: '#CFD8DC',
        blueGreyLighten: '#C8D2D7',
        fontLinkTable: '#E6E8FC',
        secondaryIconDrawer: '#9FA8DA',
        fontChip: '#455A64'
      }
    }
  }
})