<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      width="500"
      overlay-color="overlay"
    >
      <v-card class="text-center">
        <v-toolbar class="px-4" color="transparent" flat height="48">
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" class="pt-7 mr-4" justify="center">
              <v-btn @click="dialog=false" icon small><v-icon size="20" color="fontBody">mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="pb-7">
          <v-img contain :height="190" :src="require(`@/assets/alert-dialog/ax.svg`)" />
          <span class="d-block subtitle-1 mt-4 font-weight-bold primary--text">Intermitencia en el Servicio de Impuestos Internos (SII)</span>
          <v-col class="text-left pb-0">
            <span class="d-block mb-2">El Servicio de Impuestos Internos (SII) está experimentando problemas en la autenticación, lo que está generando retrasos en el envío de documentos y en la actualización de estados.</span>
            <!-- Informamos que, a partir del viernes <span class="font-weight-semibold">25 de octubre de 2024 a las 23:00 horas, </span>y hasta el sábado <span class="font-weight-semibold">26 a las 09:00 horas,</span> el Servicio de Impuestos Internos llevará a cabo un mantenimiento de los servicios que soportan la emisión y recepción de facturas, notas de crédito y notas de débito, los cuales estarán fuera de línea durante este período. -->
            <span class="mt-3 d-block">Sin embargo, las funciones automáticas de Factura Chile irán reprocesando progresivamente los documentos que aún se encuentren en estado "procesando".</span>
            <!-- <span class="d-block mt-4">
              Para más información: <a class="text-decoration-none" href="https://homer.sii.cl/" target="_blank">Consulta aquí</a>
            </span> -->
            <span class="mt-3 d-block">Atentamente, <span class="d-block">Equipo de Factura Chile.</span></span>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  export default {
    data () {
      return {
        dialog: false
     }
    },
    created () {
      this.dialog = true
    }
  }
</script>