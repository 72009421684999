import axios from "axios"
import store from "@/store"
import router from "@/router"

const http = axios.create()

http.interceptors.request.use(
  (config) => {
    // Configurar la baseURL dinámicamente en cada solicitud
    config.baseURL = store.state.global.apiServices
      ? process.env.VUE_APP_API_DOWNLOAD_DOCUMENTS
      : process.env.VUE_APP_API_BASE

    const source = axios.CancelToken.source()
    config.cancelToken = source.token
    store.commit("global/ADD_CANCEL_TOKEN", source)
    if (store.state.auth.token) {
      config.headers.Authorization = `JWT ${store.state.auth.token}`
    }
    if (store.state.auth.account.id) {
      config.headers["x-ax-workspace"] = store.state.auth.account.id
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const status = error.response?.status
    const url = error.config?.url
    if (status === 403) {
      // return router.push({name: 'PermissionDenied'})
    }
    if (status === 401 && url.endsWith("/token/refresh/")) {
      return store.commit("auth/DELETE_AUTH", {
        router: router,
        redirect: router.currentRoute.fullPath
      })
    }
    if ((status === 401 && url.endsWith("/token/")) || status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error)
      })
    }
    return store
      .dispatch("auth/REFRESH_TOKEN")
      .then((response) => {
        return new Promise((resolve, reject) => {
          http
            .request(error.config)
            .then((response) => {
              resolve(response)
            })
            .catch((error) => {
              reject(error)
            })
        })
      })
      .catch((error) => {
        Promise.reject(error)
      })
  }
)

export default http
