
const state = {
  loading: false,
  cancelTokens: [],
  apiServices: false
}

const actions = {
  SET_LOADING: ({commit}, loading) => {
    commit('SET_LOADING', loading)
  },
  CANCEL_PENDING_REQUESTS: ({commit, state}) => {
    state.cancelTokens.forEach((request) => {
      if (request.cancel) {
        request.cancel()
      }
    })
    commit('CLEAR_CANCEL_TOKENS')
  }
}

const mutations = {
  SET_LOADING: (state, loading) => {
    state.loading = loading
  },
  ADD_CANCEL_TOKEN: (state, token) => {
    state.cancelTokens.push(token)
  },
  CLEAR_CANCEL_TOKENS: (state) => {
    state.cancelTokens = []
  },
  SET_API_SERVICES: (state, value) => {
    state.apiServices = value
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
